<template>
    <div class="consumer-box sm-qu-padding">
        <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          hover
          striped
          border
          small
          fixed
          dark
          @searchData="getData"
          :pages="pages"
          @getCurrentPage="getCp"
          caption="Combined All dark Table"
        />
      </CCol>
    </CRow>
    </div>
</template>
<script>
import CTableWrapper from '../../components/ConsumerTable.vue'
import {getCardDetail} from "../../api/http"
export default {
    data() {
        return{
            data: [],
            pages: null,
            data1: [
                {
                    alias: "Jone",
                    auth_time: '2021/11/01 12:00:23',
                    merchant_name: 'Amazon Payments',
                    // ConsumerCard: 'Maurice Sevilla 1231231231231231231',
                    ConsumerCard: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    card_number: "22292988888888888",
                    auth_amt: -20,
                    auth_currency: 'USD',
                    settle_amt: -20,
                    settle_currency: -20,
                    type: '消費授權',
                    status: 'Pending'
                },
                {
                    alias: "Jone",
                    auth_time: '2021/11/01 12:00:23',
                    merchant_name: 'Amazon Payments',
                    // ConsumerCard: 'Maurice Sevilla 1231231231231231231',
                    ConsumerCard: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    card_number: "42883788888888888",
                    auth_amt: -20,
                    auth_currency: 'USD',
                    settle_amt: -20,
                    settle_currency: -20,
                    type: '消費授權',
                    status: 'Decline'
                },
                {
                    alias: "Jone",
                    auth_time: '2021/11/01 12:00:23',
                    merchant_name: 'Amazon Payments',
                    // ConsumerCard: 'Maurice Sevilla 1231231231231231231',
                    ConsumerCard: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    card_number: "42883788888888888",
                    auth_amt: -20,
                    auth_currency: 'USD',
                    settle_amt: -20,
                    settle_currency: -20,
                    type: '消費授權',
                    status: 'Decline'
                },
                {
                    alias: "Jone",
                    auth_time: '2021/11/01 12:00:23',
                    merchant_name: 'Amazon Payments',
                    // ConsumerCard: 'Maurice Sevilla 1231231231231231231',
                    ConsumerCard: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    card_number: "42883788888888888",
                    auth_amt: -20,
                    auth_currency: 'USD',
                    settle_amt: -20,
                    settle_currency: -20,
                    type: '消費授權',
                    status: 'Decline'
                },{
                    alias: "Jone",
                    auth_time: '2021/11/01 12:00:23',
                    merchant_name: 'Amazon Payments',
                    // ConsumerCard: 'Maurice Sevilla 1231231231231231231',
                    ConsumerCard: {
                        name: 'Maurice Sevilla',
                        code: '1231231231231231231'
                    },
                    auth_amt: -20,
                    auth_currency: 'USD',
                    settle_amt: -20,
                    settle_currency: -20,
                    type: '消費授權',
                    status: 'Decline'
                }
            ]
        }
    },
    computed: {
        
    },
    methods: {
        getData(before, after, card_number, tag, status, page) {
           let data = {
                type: "get_card_transaction",
                uid: window.localStorage.getItem("uid"),
                before: before || "",
                after: after || "",
                card_number: card_number || "",
                tag: tag || "",
                status: status || "",
                // ttype: ttype || "",
                page: page || 1,
                page_size: 20
            }
            getCardDetail(data).then(res => {
                // console.log('res==>', res)
                this.pages = Number(res.size)
                this.data = res.data
            })
        },
        getCp(val) {
            console.log("val==>", val)
            return val
        }
        
    },
    created() {
        // this.getData()
    //   if(this.$route.query.card_num !== undefined) {
    //   this.forms.card = this.$route.query.card_num
    //    this.getData(before="", after="", card_number=this.$route.query.card_num, tag="", status="", page=1)
    //   this.$route.query.card_num = undefined
    // }else {
    //     this.getData()
    // }
        // this.getData("","",this.$route.query.card_num ? this.$route.query.card_num : "")
        // this.getData()
    },
    components: {
        CTableWrapper
    }
}
</script>
<style scoped>
.consumer-box{
    padding-left: 2.5rem;
    overflow: hidden;
}
@media screen and (max-width: 520px){
    .sm-qu-padding{
      padding: 0;
    }
  }
</style>